import store from "./../../store/index"

export let hairStatePreCheck = {
  dynamicScript: true,
  buildScript: () => buildScript()
}

async function buildScript() {
  hairStatePreCheck.scriptElements = [
    {
      id: "hairquiz_precheck",
      type: "DecisionElement",
      query: "EXISTING_HAIR_STATE",
      responseSequence: {
        true: ["hairquiz_check", "hairquiz_check_response"],
        false: ["hairquiz_check_response"] //hairquiz_intro
      }
    },
    {
      id: "hairquiz_check",
      type: "MessageElement",
      conversationElement: hairStateRecapMessage(),
      duration: 2000
    },
    {
      id: "hairquiz_check_response",
      type: "InputElement",
      conversationElement: {
        messageType: "ResponseMessage",
        responseType: "ResponseButtonSet",
        id: "hairquiz_check_response",
        multiSelect: 1,
        text: null,
        answers: [
          {
            id: "no",
            text: "Take Hair Quiz"
          },
          {
            id: "changeall",
            text: "Configure Hair State"
          }
        ]
      },
      duration: 1000,
      responseSequence: {
        yes: ["hairstate_confirmed", "script_end"],
        no: ["hairquiz_intro_redo", "goto_quiz"],
        changeall: ["hairstate_changeAll", "script_end"]
      }
    },
    {
      id: "hairstate_confirmed",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Great news!"
      },
      duration: 2000
    },
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    {
      id: "hairquiz_intro",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text:
          "Right, I'm going to ask you some questions about your hair. Ready?"
      },
      duration: 2000
    },
    {
      id: "hairquiz_intro_redo",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "No problem, we'll just do a quick refresher. Ready?"
      },
      duration: 2000
    },
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    {
      id: "goto_quiz",
      type: "ActionElement",
      action: "INJECT_NEW_SCRIPT",
      payload: ["hairStateQuiz"]
    },
    {
      id: "script_end",
      type: "ActionElement",
      action: "APPEND_NEW_SCRIPT",
      payload: ["genericFollowUpScript"]
    },
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    {
      id: "hairstate_changeAll",
      type: "MessageElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: null,
        component: "HairStateCarousel",
        payload: {}
      },
      duration: 2000,
      responseSequence: {
        done: ["script_end"]
      }
    }
  ]
}

const hairStateRecapMessage = function() {
  let _state = store.getters["account/getHairState"]

  if (_state && _state.length) {
    return {
      messageType: "DefaultMessage",
      text: `According to my spies:\n${generateNarrativeState()}\n ...is this correct?`
    }
  } else {
    return {
      messageType: "DefaultMessage",
      text:
        "**TEMP** I'm having a bit of trouble checking your hair state just now. Wibble"
    }
  }

  // for each attribute, create the phrase 'your _attr_ is _value_', and stitch them up all nice likes
  function generateNarrativeState() {
    let _str = ""
    for (let i = 0; i < _state.length; i++) {
      _str = _str.concat(
        `${i < _state.length - 1 ? "\n" : "\nand "}your ${_state[i].name} is ${
          _state[i].value
        }${i < _state.length - 1 ? "," : ""}`
      )
    }
    return _str
  }
}

export default hairStatePreCheck
