// import store from "./../../store/index"
import ContentfulGQL from "../ContentfulGQL"
// import BleachAPI from "./../BleachAPI"

// let hairStateModel = null
//let hairState = null
let hairStateQuizScreen = {
  dynamicScript: true,
  buildScript: entryId => buildScript(entryId)
}

async function buildScript() {
  // let { contentModels } = store.state.content

  console.log("buildScript")

  const hairState = await ContentfulGQL.fetchAllOfContentType(
    "HairState",
    null,
    1
  )

  console.log("hairState", hairState[0])

  const hairStateModel = (hairStateQuizScreen.hairStateModel = hairState[0])

  // hairStateModel.attributes = await Promise.all(hairStateModel.attributes.map(attribute => {
  //   return async function() {
  //     let { attribute_id, name, values } = await ContentfulGQL.fetchEntryById('HairStateAttribute',attribute.sys.id)
  //     values
  //   }
  // }))

  // const hairStateModel = (hairStateQuizScreen.hairStateModel = {
  //   attributes: contentModels.hairState[entryId].attributes.map(async attribute => {
  //     let { attribute_id, name, values } = contentModels.hairStateAttribute[
  //       attribute.referenceId
  //     ]
  //     return {
  //       attribute_id,
  //       name,
  //       values: values.map(async value => {
  //         let {
  //           value_id,
  //           name,
  //           index_key,
  //           image
  //         } = contentModels.hairStateValue[value.referenceId]
  //         return {
  //           value_id,
  //           name,
  //           index_key,
  //           image
  //         }
  //       })
  //     }
  //   })
  // })

  hairStateQuizScreen.scriptElements = [
    {
      id: "hairquiz_q1",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: "hair_quiz_question_desired_look",
        component: "PanelSwiper",
        payload: getInputPayload(hairStateModel, "hair_attribute_desired_look")
      },
      duration: null,
      responseSequence: null
    },

    {
      id: "hairquiz_q2",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: "hair_quiz_question_desired_technique",
        component: "PanelSwiper",
        payload: getInputPayload(
          hairStateModel,
          "hair_attribute_desired_technique"
        )
      },
      duration: null,
      responseSequence: {
        desired_technique_roots: ["hairquiz_q2_extra_roots", "hairquiz_q3"],
        desired_technique_roots_to_ends_single_colour: ["hairquiz_q3"],
        desired_technique_roots_to_ends_multi_colour: [
          "hairquiz_q2_extra_multi",
          "hairquiz_q3"
        ],
        desired_technique_balayage: ["hairquiz_q3"],
        desired_technique_bits: ["hairquiz_q3"],
        desired_technique_highlights: ["hairquiz_q3"]
      }
    },
    {
      id: "hairquiz_q2_extra_roots",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: "hair_quiz_question_root_length",
        component: "DefaultResponse",
        payload: getInputPayload(hairStateModel, "hair_attribute_root_length")
      },
      duration: null,
      responseSequence: null
    },
    {
      id: "hairquiz_q2_extra_multi",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: "hair_quiz_question_colour_technique",
        component: "DefaultResponse",
        payload: getInputPayload(
          hairStateModel,
          "hair_attribute_desired_multi_colour_technique"
        )
      },
      duration: null,
      responseSequence: null
    },

    {
      id: "hairquiz_q3",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: "hair_quiz_question_natural_colour",
        component: "PanelSwiper",
        payload: getInputPayload(hairStateModel, "hair_attribute_natural_shade")
      },
      duration: null,
      responseSequence: null
    },

    {
      id: "hairquiz_q4",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: "hair_quiz_question_hair_type",
        component: "HairType",
        payload: getInputPayload(hairStateModel, "hair_attribute_type")
      },
      duration: null,
      responseSequence: null
    },

    {
      id: "hairquiz_q5",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: "hair_quiz_question_length",
        component: "PanelSwiper",
        payload: getInputPayload(hairStateModel, "hair_attribute_length")
      },
      duration: null,
      responseSequence: null
    },

    // {
    //   id: "hairquiz_q6",
    //   type: "InputElement",
    //   conversationElement: {
    //     messageType: "ComponentMessage",
    //     text: "hair_quiz_question_density",
    //     component: "PanelSwiper",
    //     payload: getInputPayload(hairStateModel, "hair_attribute_density")
    //   },
    //   duration: null,
    //   responseSequence: null
    // },
    // {
    //   id: "hairquiz_q7",
    //   type: "InputElement",
    //   conversationElement: {
    //     messageType: "ComponentMessage",
    //     text: "hair_quiz_question_texture",
    //     component: "PanelSwiper",
    //     payload: getInputPayload(hairStateModel, "hair_attribute_texture")
    //   },
    //   duration: null,
    //   responseSequence: null
    // },

    {
      id: "hairquiz_q8",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: "hair_quiz_question_current_state",
        component: "PanelSwiper",
        payload: getInputPayload(hairStateModel, "hair_attribute_current_state")
      },
      duration: null,
      responseSequence: null
    },

    {
      id: "hairquiz_q9",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: "hair_quiz_question_experience",
        component: "PanelSwiper",
        payload: getInputPayload(
          hairStateModel,
          "hair_attribute_experience_level"
        )
      },
      duration: null,
      responseSequence: null
    },
    {
      id: "script_end",
      type: "ActionElement",
      action: "SET_QUIZ_STATUS",
      payload: "post"
    }
  ]

  return hairState[0]
}

const getInputPayload = (hairStateModel, attribute, assets) => {
  let options = getOptions(hairStateModel, attribute, assets)
  return options
    ? {
        // selected: getSelectedValue(hairState, hairStateModel, attributeType),
        attribute,
        options /* ,
        onSubmit: generateSubmit(attribute) */
      }
    : null
}

// const getSelectedValue = (hairState, hairStateModel, attributeType) => {
//   const hairStateEntry = hairState.find(el => el.name == attributeType)

//   if (!hairStateEntry) {
//     return undefined
//   }

//   return hairStateModel.attributes
//     .find(el => el.attribute_id == attributeType)
//     .values.find(el => el.value_id == hairStateEntry.value).name
// }

const getOptions = (hairStateModel, attribute) => {
  // console.log(hairStateModel, attribute)
  let targetAttribute = hairStateModel.attributesCollection.items.find(
    el => el.attributeId == attribute
  )
  return targetAttribute ? targetAttribute.valuesCollection.items : null
}

/* const generateSubmit = attributeType => response =>
  BleachAPI.submitUserHairState({
    question: attributeType,
    response
  }) */

// export { getInputPayload }

export default hairStateQuizScreen
