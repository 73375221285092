import store from "./../../store/index"
import BleachAPI from "./../BleachAPI"

let hairStateModel = null
let hairState = null
let hairStateQuiz = {
  dynamicScript: true,
  buildScript: entryId => buildScript(entryId)
}

async function buildScript(entryId) {
  let { hairStateData } = store.state.content
  hairState = store.state.account.hairState

  hairStateModel = {
    attributes: hairStateData.hairState[entryId].attributes.map(attribute => {
      let { attribute_id, name, values } = hairStateData.hairStateAttribute[
        attribute.sys.id
      ]
      return {
        attribute_id,
        name,
        values: values.map(value => {
          let { value_id, name, index_key } = hairStateData.hairStateValue[
            value.sys.id
          ]
          return {
            value_id,
            name,
            index_key
          }
        })
      }
    })
  }

  hairStateQuiz.scriptElements = [
    {
      id: "quiz_init",
      type: "ActionElement",
      action: "UPDATE_SCRIPT_FLAGS",
      payload: {
        HAIR_QUIZ_IN_PROGRESS: true
      }
    },
    {
      id: "hairquiz_q1",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "What sort of texture is your hair?"
      },
      duration: 2000
    },
    {
      id: "hairquiz_q1_response",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: null,
        component: "SlideSelect",
        payload: getInputPayload(
          hairState,
          hairStateModel,
          "hair_attribute_type",
          [
            "https://assets.bleachlondon.com/image/upload/v1599052505/platform_assets/curly.svg",
            "https://assets.bleachlondon.com/image/upload/v1599041927/platform_assets/wavy.svg"
          ]
        )
      },
      duration: 1000
    },
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    {
      id: "hairquiz_q2",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text:
          "Great, we're going from strength to strength! So, how about your length?"
      },
      duration: 2000 // wait 2 sec before showing next message
    },
    {
      id: "hairquiz_q2_response",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: null,
        component: "SlideSelect",
        payload: getInputPayload(
          hairState,
          hairStateModel,
          "hair_attribute_length",
          [
            "https://assets.bleachlondon.com/image/upload/v1599052505/platform_assets/curly.svg",
            "https://assets.bleachlondon.com/image/upload/v1599041927/platform_assets/wavy.svg"
          ]
        )
      },
      duration: 1000
    },
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    {
      id: "hairquiz_q3",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "How about the diameter?"
      },
      duration: 2000 // wait 2 sec before showing next message
    },
    {
      id: "hairquiz_q3_response",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: null,
        component: "SlideSelect",
        payload: getInputPayload(
          hairState,
          hairStateModel,
          "hair_attribute_diameter",
          [
            "https://assets.bleachlondon.com/image/upload/v1599052505/platform_assets/curly.svg",
            "https://assets.bleachlondon.com/image/upload/v1599041927/platform_assets/wavy.svg"
          ]
        )
      },
      duration: 1000
    },
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    {
      id: "hairquiz_q4",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "How thick is your thatch?"
      },
      duration: 3000 // wait 2 sec before showing next message
    },
    {
      id: "hairquiz_q4_response",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: null,
        component: "SlideSelect",
        payload: getInputPayload(
          hairState,
          hairStateModel,
          "hair_attribute_mass",
          [
            "https://assets.bleachlondon.com/image/upload/v1599052505/platform_assets/curly.svg",
            "https://assets.bleachlondon.com/image/upload/v1599041927/platform_assets/wavy.svg"
          ]
        )
      },
      duration: 1000
    },
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    {
      id: "hairquiz_q5",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Let's go back to your roots. What's your natural colour?"
      },
      duration: 2000 // wait 2 sec before showing next message
    },
    {
      id: "hairquiz_q5_response",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: null,
        component: "SwatchSelect",
        payload: getInputPayload(
          hairState,
          hairStateModel,
          "hair_attribute_shade",
          [
            "https://assets.bleachlondon.com/image/upload/v1599060482/platform_assets/yellowSquare.svg",
            "https://assets.bleachlondon.com/image/upload/v1599755411/platform_assets/pink.svg"
          ]
        )
      },
      duration: 1000
    },
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    {
      id: "hairquiz_q6",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "...and your natural tone?"
      },
      duration: 2000 // wait 2 sec before showing next message
    },
    {
      id: "hairquiz_q6_response",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: null,
        component: "SwatchSelect",
        payload: getInputPayload(
          hairState,
          hairStateModel,
          "hair_attribute_natural_tone",
          [
            "https://assets.bleachlondon.com/image/upload/v1599060482/platform_assets/yellowSquare.svg",
            "https://assets.bleachlondon.com/image/upload/v1599755411/platform_assets/pink.svg"
          ]
        )
      },
      duration: 1000
    },
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    {
      id: "hairquiz_q7",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "How would you describe its current condition?"
      },
      duration: 2000 // wait 2 sec before showing next message
    },
    {
      id: "hairquiz_q7_response",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: null,
        component: "SlideSelect",
        payload: getInputPayload(
          hairState,
          hairStateModel,
          "hair_attribute_condition",
          [
            "https://assets.bleachlondon.com/image/upload/v1599052505/platform_assets/curly.svg",
            "https://assets.bleachlondon.com/image/upload/v1599041927/platform_assets/wavy.svg"
          ]
        )
      },
      duration: 1000
    },
    {
      id: "quiz_completed",
      type: "ActionElement",
      action: "UPDATE_SCRIPT_FLAGS",
      payload: {
        HAIR_QUIZ_IN_PROGRESS: false
      }
    },
    {
      id: "update_user",
      type: "ActionElement",
      action: "REFRESH_USER_ACCOUNT_INFO",
      payload: null
    },
    {
      id: "script_end",
      type: "ActionElement",
      action: "APPEND_NEW_SCRIPT",
      payload: null
    }
  ]
}

const getInputPayload = (hairState, hairStateModel, attributeType, assets) => {
  return {
    selected: getSelectedValue(hairState, hairStateModel, attributeType),
    options: getOptions(hairStateModel, attributeType, assets),
    onSubmit: generateSubmit(attributeType)
  }
}

const getSelectedValue = (hairState, hairStateModel, attributeType) => {
  const hairStateEntry = hairState.find(el => el.name == attributeType)

  if (!hairStateEntry) {
    return undefined
  }

  return hairStateModel.attributes
    .find(el => el.attribute_id == attributeType)
    .values.find(el => el.value_id == hairStateEntry.value).name
}
const getOptions = (hairStateModel, attributeType, assets) =>
  hairStateModel.attributes
    .find(el => el.attribute_id == attributeType)
    .values.map(value =>
      Object.assign(value, {
        asset: assets[Math.floor(Math.random() * assets.length)]
      })
    )

const generateSubmit = attributeType => selected =>
  BleachAPI.submitUserHairState({
    question: attributeType,
    response: selected.value_id
  }).then(() => {
    store.dispatch(
      "account/SET_HAIR_STATE_ATTRIBUTE",
      {
        name: attributeType,
        value: selected.value_id
      },
      { root: true }
    )
  })

export { getInputPayload }

export default hairStateQuiz
