export default {
  scriptElements: [
    {
      id: "intro",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Is there anything else I can help you with today?"
      },
      duration: 2000
    },
    {
      id: "generic_01",
      type: "InputElement",
      conversationElement: {
        messageType: "ResponseMessage",
        responseType: "ResponseButtonSet",
        id: "example_id_for_need_more_help?",
        multiSelect: 1,
        text: null,
        answers: [
          {
            id: "a",
            text: "Talk to a stylist"
          },
          {
            id: "b",
            text: "Shop some looks"
          }
        ]
      },
      duration: 1000,
      responseSequence: {
        a: ["redirect_to_cs"], // inject multiple elements into the script, or just one
        b: ["generic_02", "end_chat"]
      }
    },
    {
      id: "generic_02",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Suit yourself, boring conversation anyway"
      },
      duration: 2000
    },
    {
      id: "redirect_to_cs",
      type: "ActionElement",
      action: "APPEND_NEW_SCRIPT",
      payload: ["chatLaunchScript"]
    },
    {
      id: "end_chat",
      type: "ActionElement",
      action: "CLOSE_CHAT",
      payload: null
    }
  ]
}
